import { default as indexp27Pcrgr9FMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/[...slug]/index.vue?macro=true";
import { default as indexw8sLZIhnTPMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/article/index.vue?macro=true";
import { default as indexZk7UD9FGIlMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/benefit/index.vue?macro=true";
import { default as indextDQPg9wdGIMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/benefits/index.vue?macro=true";
import { default as indexRLJwpoyqQNMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/benefitsOverview/index.vue?macro=true";
import { default as index83JM7TpmruMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/checkout/validate/index.vue?macro=true";
import { default as _91id_93Ww1SM4TO3zMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/form/[id].vue?macro=true";
import { default as _91id_93l0m4uRe9jYMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/form/confirmation/[id].vue?macro=true";
import { default as index1NFhxl339CMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/index.vue?macro=true";
import { default as indexLtFOfKV2nEMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/magazine/index.vue?macro=true";
import { default as indexA7jGssr4AcMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/magazineOverview/index.vue?macro=true";
import { default as indexGbX8hIkmrIMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/node/[nid]/index.vue?macro=true";
import { default as index9QBMVHQl4VMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/search/index.vue?macro=true";
import { default as indexv2Ue2bwouWMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/start/index.vue?macro=true";
import { default as indexJRSql3JpElMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/user/[id]/index.vue?macro=true";
import { default as indexQpuPnaHRy4Meta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/user/login/index.vue?macro=true";
import { default as indexCk9P4x0pITMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/user/register/index.vue?macro=true";
import { default as _91_91login_93_93WUjCTeWZTEMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/user/reset/[id]/[timestamp]/[hash]/[[login]].vue?macro=true";
import { default as indexyd9ouNozAAMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/user/reset/index.vue?macro=true";
import { default as component_45stubsuIc9iPj7MMeta } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubsuIc9iPj7M } from "/home/portal/domains/stage.famillesuisse.ch/www/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: indexp27Pcrgr9FMeta || {},
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "node-article",
    path: "/article",
    meta: indexw8sLZIhnTPMeta || {},
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/article/index.vue").then(m => m.default || m)
  },
  {
    name: "node-benefit",
    path: "/benefit",
    meta: indexZk7UD9FGIlMeta || {},
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/benefit/index.vue").then(m => m.default || m)
  },
  {
    name: "taxonomy-benefits",
    path: "/benefits",
    meta: indextDQPg9wdGIMeta || {},
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: "benefits-overview",
    path: "/benefitsOverview",
    meta: indexRLJwpoyqQNMeta || {},
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/benefitsOverview/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-validate",
    path: "/checkout/validate",
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/checkout/validate/index.vue").then(m => m.default || m)
  },
  {
    name: "webform-route",
    path: "/form/:id()",
    meta: _91id_93Ww1SM4TO3zMeta || {},
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/form/[id].vue").then(m => m.default || m)
  },
  {
    name: "webform-confirmation",
    path: "/form/confirmation/:id()",
    meta: _91id_93l0m4uRe9jYMeta || {},
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/form/confirmation/[id].vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/",
    meta: index1NFhxl339CMeta || {},
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "taxonomy-categories",
    path: "/magazine",
    meta: indexLtFOfKV2nEMeta || {},
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: "magazine-overview",
    path: "/magazineOverview",
    meta: indexA7jGssr4AcMeta || {},
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/magazineOverview/index.vue").then(m => m.default || m)
  },
  {
    name: "node-canonical",
    path: "/node/:nid()",
    meta: indexGbX8hIkmrIMeta || {},
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/node/[nid]/index.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    meta: index9QBMVHQl4VMeta || {},
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "start",
    path: "/start",
    meta: indexv2Ue2bwouWMeta || {},
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/start/index.vue").then(m => m.default || m)
  },
  {
    name: "user-overview",
    path: "/user/:id()",
    meta: indexJRSql3JpElMeta || {},
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/user/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/user/login",
    meta: indexQpuPnaHRy4Meta || {},
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/user/login/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/user/register",
    meta: indexCk9P4x0pITMeta || {},
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/user/register/index.vue").then(m => m.default || m)
  },
  {
    name: "user-reset-id-timestamp-hash-login",
    path: "/user/reset/:id()/:timestamp()/:hash()/:login?",
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/user/reset/[id]/[timestamp]/[hash]/[[login]].vue").then(m => m.default || m)
  },
  {
    name: "user-reset",
    path: "/user/reset",
    meta: indexyd9ouNozAAMeta || {},
    component: () => import("/home/portal/domains/stage.famillesuisse.ch/www/frontend/pages/user/reset/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubsuIc9iPj7MMeta?.name,
    path: "/account/register",
    component: component_45stubsuIc9iPj7M
  },
  {
    name: component_45stubsuIc9iPj7MMeta?.name,
    path: "/account/login",
    component: component_45stubsuIc9iPj7M
  },
  {
    name: component_45stubsuIc9iPj7MMeta?.name,
    path: "/account",
    component: component_45stubsuIc9iPj7M
  }
]